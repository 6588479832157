// menu
body {
    position: relative;
}

nav.general {
    @media (max-width: 768px) {
        padding-top: 80px;
    }
    ul {
        list-style: none;
    }
    > ul {
        display: grid; /* specificăm că aceasta este o grilă */
        grid-template-columns: 1fr 1fr; /* împărțim grila în două coloane egale */
        grid-template-rows: repeat(2, 1fr) repeat(3, 100px); /* specificăm numărul de rânduri */
        margin: 0 20px;

        .box1,
        .box3 {
            grid-column: 1; /* specificăm că acest element va fi plasat în prima coloană */
        }

        .box2,
        .box4,
        .box5 {
            grid-column: 2; /* specificăm că acest element va fi plasat în a doua coloană */
        }

        .box1 {
            grid-row: 1 / span 2; /* specificăm că acest element va fi plasat pe primul rând și va ocupa 2 rânduri */
        }
        .box3 {
            grid-row: 3 / span 5; /* specificăm că acest element va fi plasat pe primul rând și va ocupa 2 rânduri */
        }

        .box4 {
            grid-row: 2 / span 3; /* specificăm că acest element va fi plasat pe al treilea rând și va ocupa 2 rânduri */
        }

        .box5 {
            grid-row: 5 / 7; /* specificăm că acest element va fi plasat pe al cincilea rând */
        }

        > li {
            border: 1px solid #fff;
            padding: 2vw;
            transition: background-color 200ms linear;
            &:hover {
                background-color: var(--gradient-start);
            }

            > a {
                text-transform: uppercase;
            }

            a {
                color: #fff;
                font-size: 16px;
                @media (min-width: 768px) {
                    font-size: 30px;
                }
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    transition: all 200ms ease;
                    height: 5px;
                    background-color: #000;
                }
                &:hover {
                    color: #000;
                    &:before {
                        width: 100%;
                    }
                }
            }
        }

        &.submenu {
            li {
                a {
                    font-size: 14px;
                    @media (min-width: 768px) {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

#industries-list [data-value] {
    cursor: pointer;
}

#contact label {
    display: block;
}

// Project page

.position-relative {
    position: relative;
}

.swiper-button-next,
.swiper-button-prev {
    color: #333;
}

.back-to-first-slide,
.back-to-first-slide-how {
    position: absolute;
    top: -5px;
    right: 0;
    a {
        position: relative;
        font-size: 34px;
        line-height: 1;
        color: #ccc;
    }
}

.team-member {
    .text-center {
        text-align: center;
    }
    h3 {
        margin-top: 5px;
    }
}

.white-popup-block {
    max-width: 620px;
    @media (min-width: 768px) {
        max-width: 1200px;
        padding: 60px;
    }
    width: 100%;
    padding: 30px;
    margin: 65px auto 0;
    position: relative;
    background-color: #fff;
    img {
        max-width: 220px;
        margin: 20px 0 20px;
    }
    .mfp-close {
        z-index: 9999;
        color: #ccc;
        font-size: 30px;
    }
    .mfp-close i {
        pointer-events: none;
    }
}

g {
    &[data-value] {
        cursor: pointer;
    }
}
.swiper-slide,
.bgc-grey {
    background-color: #f9fbfa;
}

// mission SVG style
.mission-svg {
    .st0 {
        filter: url(#Adobe_OpacityMaskFilter);
    }
    .st1 {
        mask: url(#SVGID_1_);
    }
    .st2 {
        fill: #fbfbfb;
    }
    .st3 {
        opacity: 0.2;
        fill: #f9f9f9;
    }
    .st4 {
        fill: none;
        stroke: #c9cad5;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 15;
    }
    .st5 {
        fill: #ffffff;
        stroke: #e3e3e3;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }
    .st6 {
        fill: none;
        stroke: #202d52;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 15.0994;
    }
    .st7 {
        fill: #2b2b2b;
    }
    .st8 {
        fill: #ffffff;
    }
    .st9 {
        fill: none;
        stroke: #c9c9d6;
        stroke-width: 1.103;
    }
    .st10 {
        filter: url(#Adobe_OpacityMaskFilter_00000119804503485005447350000017309031178240299904_);
    }
    .st11 {
        mask: url(#SVGID_00000038376209667883697980000004335797560791557279_);
    }
    .st12 {
        filter: url(#Adobe_OpacityMaskFilter_00000050665098773050779190000004426034740855776446_);
    }
    .st13 {
        mask: url(#SVGID_00000003081919921517491580000017244313996626142080_);
    }
    .st14 {
        fill: none;
        stroke: #e6e6e6;
        stroke-width: 20;
        stroke-miterlimit: 10;
    }
    .st15 {
        opacity: 0.7;
    }
    .st16 {
        filter: url(#Adobe_OpacityMaskFilter_00000169530270211679010270000005481224139473992085_);
    }
    .st17 {
        mask: url(#SVGID_00000052805203640380042610000018149341839011220648_);
    }
    .st18 {
        fill: #f6f6f6;
    }
    .st19 {
        clip-path: url(#SVGID_00000029030426719892425720000009395912907467028386_);
    }
    .st20 {
        filter: url(#Adobe_OpacityMaskFilter_00000156559303561073376670000001039998671200643505_);
    }
    .st21 {
        clip-path: url(#SVGID_00000095330096930731559510000010391904044510147239_);
    }

    .st22 {
        clip-path: url(#SVGID_00000095330096930731559510000010391904044510147239_);
        mask: url(#SVGID_00000134223249567665416910000003976256832571052717_);
    }
    .st23 {
        opacity: 0.45;
        clip-path: url(#SVGID_00000093879874820750485570000006143746063433864071_);
        fill: #d9b153;
    }
    .st24 {
        clip-path: url(#SVGID_00000095330096930731559510000010391904044510147239_);
        fill: #ffffff;
    }
    .st25 {
        clip-path: url(#SVGID_00000095330096930731559510000010391904044510147239_);
        fill: none;
        stroke: #c9c9d6;
        stroke-width: 1.103;
    }
    .st26 {
        filter: url(#Adobe_OpacityMaskFilter_00000093877479913983597800000013056397127487398803_);
    }

    .st27 {
        clip-path: url(#SVGID_00000095330096930731559510000010391904044510147239_);
        mask: url(#SVGID_00000124122098503767294660000007572101767730935700_);
    }
    .st28 {
        opacity: 0.45;
        clip-path: url(#SVGID_00000024720368580123961370000014089857498962422445_);
        fill: #b0af56;
    }
    .st29 {
        filter: url(#Adobe_OpacityMaskFilter_00000091706993708249384680000007643380417102205879_);
    }

    .st30 {
        clip-path: url(#SVGID_00000095330096930731559510000010391904044510147239_);
        mask: url(#SVGID_00000079447028332896744400000014596483774206148273_);
    }
    .st31 {
        opacity: 0.4;
        clip-path: url(#SVGID_00000122680627303951708640000002501002060433503371_);
        fill: #006751;
    }
}

// impact partnership
.impact-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    > svg {
        max-width: 180px;
        @media (min-width: 768px) {
            max-width: 320px;
        }
        padding-top: 30px;
    }
}

.partner {
    [data-value="1"] {
        // -webkit-filter: drop-shadow(0 0 10px rgba(117, 76, 18, 0.9));
        filter: url(#shadow-turism);
    }
    [data-value="2"] {
        filter: url(#shadow-edu);
    }
    [data-value="3"] {
        filter: url(#shadow-media);
    }
    [data-value="4"] {
        filter: url(#shadow-funds);
    }
    [data-value="5"] {
        filter: url(#shadow-more);
    }
    [data-value="6"] {
        filter: url(#shadow-film);
    }
    [data-value="7"] {
        filter: url(#shadow-env);
    }
    [data-value="8"] {
        filter: url(#shadow-gov);
    }
    [data-value="9"] {
        filter: url(#shadow-cur);
    }
}

.impact-image__turism svg {
    filter: drop-shadow(0 0 10px rgba(117, 76, 18, 0.9));
}

.impact-image__edu svg,
.shadow-mod-5 {
    filter: drop-shadow(0 0 10px #a4792a);
    filter: url(#shadow-edu);
}
.impact-image__media svg {
    filter: drop-shadow(0 0 10px #ce986b);
}

.impact-image__funds svg,
.shadow-mod-2 {
    filter: drop-shadow(0 0 10px #d9b153);
    filter: url(#shadow-funds);
}

.impact-image__more svg {
    filter: drop-shadow(0 0 10px #ebe6e1);
}

.impact-image__film svg,
.shadow-mod-4 {
    filter: drop-shadow(0 0 10px #b0b856);
    filter: url(#shadow-film);
}

.impact-image__env svg,
.shadow-mod-3 {
    filter: drop-shadow(0 0 10px #677022);
    filter: url(#shadow-env);
}

.impact-image__gov svg,
.shadow-mod-6 {
    filter: drop-shadow(0 0 10px #195b29);
    filter: url(#shadow-gov);
}

.impact-image__cur svg,
.shadow-mod-1 {
    filter: drop-shadow(0 0 10px #006751);
    filter: url(#shadow-cur);
}

.partner {
    .st0 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.5;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 6, 6;
    }
    .st1 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.47;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 5.64, 5.64;
    }
    .st2 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.44;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 5.27, 5.27;
    }
    .st3 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.4;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 4.91, 4.91;
    }
    .st4 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.37;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 4.55, 4.55;
    }
    .st5 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.34;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 4.18, 4.18;
    }
    .st6 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.31;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 3.82, 3.82;
    }
    .st7 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.28;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 3.45, 3.45;
    }
    .st8 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.25;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 3.09, 3.09;
    }
    .st9 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.21;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 2.73, 2.73;
    }
    .st10 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.18;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 2.36, 2.36;
    }
    .st11 {
        fill: none;
        stroke: #2b2b2b;
        stroke-width: 0.15;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 2, 2;
    }
    .st12 {
        fill: #ffffff;
        stroke: #c9c9d6;
        stroke-width: 0.9;
    }
    .st13 {
        fill: none;
    }
    .st14 {
        fill: none;
        stroke: #808080;
        stroke-width: 0.9;
    }
    .st15 {
        fill: #2b2b2b;
    }
    .st16 {
        fill: #ffffff;
        stroke: #808080;
        stroke-miterlimit: 10;
    }
    .st17 {
        fill: none;
        stroke: #808080;
        stroke-miterlimit: 10;
    }
    .st18 {
        fill: #ffffff;
        stroke: #e3e3e3;
        stroke-width: 2;
        stroke-miterlimit: 10;
    }
    .st19 {
        fill: none;
        stroke: #c9cad5;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 0, 0, 0, 14.94;
    }
    .st20 {
        fill: none;
        stroke: #808080;
        stroke-width: 0.8;
        stroke-miterlimit: 10;
    }
    .st21 {
        fill: #808080;
    }
    .st22 {
        fill: #231f20;
        stroke: #808080;
        stroke-miterlimit: 10;
    }
}

#contact {
    padding: 50px 0;
}

#contact,
#contact input,
#contact textarea {
    background-color: #1a1a1a;
    color: #efefef;
}

#contact input,
#contact textarea {
    width: 100%;
    -webkit-appearance: none;
    border: 1px solid #efefef;
    padding: 5px;
}

#contact label {
    margin-bottom: 5px;
}

#contact h2 {
    color: #ffffff;
    font-size: 30px;
}

#contact a {
    color: #fff;
    text-decoration: underline;
}

.btn-custom {
    text-align: center;
    display: block;
    width: 100%;
    padding: 10px 0.75rem;
    border-radius: 25px;
    color: #1a1a1a;
    border: 1px solid #1a1a1a;
    margin-bottom: 10px;
    background-color: #ffffff;
    transition: all 200ms linear;
    &:hover {
        background-color: #efefef;
    }
}

.btn.btn-primary {
    background-color: #efefef;
    transition: all 200ms linear;
    border: none;
    padding: 10px 45px;
    font-size: 16px;
    border-radius: 10px;
    text-transform: uppercase;
    color: #1a1a1a;
}

.btn.btn-primary:hover {
    background-color: #b0b1b7;
}

.alert {
    color: #ff0000;
}

.tap-to-see-team {
    cursor: pointer;
}

// how page

.shadow-1 {
    filter: url(#shadow-1);
}

.shadow-2 {
    filter: url(#shadow-2);
}

.shadow-3 {
    filter: url(#shadow-3);
}

.shadow-4 {
    filter: url(#shadow-4);
}

.shadow-5 {
    filter: url(#shadow-5);
}

.shadow-6 {
    filter: url(#shadow-6);
}

.shadow-7 {
    filter: url(#shadow-7);
}

.shadow-8 {
    filter: url(#shadow-8);
}

.shadow-9 {
    filter: url(#shadow-9);
}

.shadow-10 {
    filter: url(#shadow-10);
}

#how-slider .swiper-slide img,
#mission .swiper-slide img {
    @media (min-width: 768px) {
        max-width: 1000px;
    }
    margin: 0 auto;
    display: block;
}

#adaptable-slider,
#oasis-slider,
#origenes-slider,
#natures-slider {
    margin-bottom: 25px !important;
}

.line-top {
    margin-top: 10px;
    border-top: 1px solid #efefef;
    padding-top: 20px;
    font-size: 16px;
}

.footer-right {
    @media (min-width: 768px) {
        text-align: right;
    }
}
